
@media only screen and (min-width: 1500px) {
.ttt1 {

    padding: 50px 50px 20px 50px;
    overflow: hidden;
    height: 50vh ; 
    max-height: 444vh;
    border-bottom: 6vh solid transparent ;
    text-overflow:ellipsis ;

  }


  
.ttt2 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}}


@media only screen and (max-width: 1500px) {
    .ttt2 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;

    }
    .ttt1 {

        padding: 50px 50px 20px 50px;
        overflow: hidden;
        border-bottom: 6vh solid transparent ;
        text-overflow:ellipsis ;
    
      }
  }